"use client";

import { type Country } from "@prisma/client";

import { IANA_TIME_ZONE_TO_COUNTRY } from "./timeZoneToCountry";

// eventually, solved by vercel
export const getCountryFromTimezone = (): Country => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const country = IANA_TIME_ZONE_TO_COUNTRY[timeZone as keyof typeof IANA_TIME_ZONE_TO_COUNTRY];

  if (country === "Norway") {
    return "NO";
  } else if (country === "Denmark") {
    return "DK";
  } else if (country === "Kenya") {
    return "KE";
  }
  return "WW";
};
